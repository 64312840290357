import { Box, createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import MainRouter from "./routers/main-router";
// import { Footer } from "./components/footer/footer";
// import { NavigationBar } from "./components/navigation/navigation-bar";

// import { FursanRoutes } from "./routes/Routes";

const theme = createTheme({
	palette: {
		primary: {
			main:
				window.document.location.origin.toLocaleLowerCase() ===
				"https://pop.watarelsharq.com"
					? "#0c8eb6"
					: "#091a86",
			contrastText: "white",
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Box
				className="App"
				sx={{
					maxWidth: "100%",
					position: "relative",
					bgcolor: "#f5f5f5",
					minHeight: "100vh",

					"& *": {
						fontFamily: "'Almarai', sans-serif !important",
					},
				}}
			>
				{window.document.location.origin.toLocaleLowerCase() ===
				"https://pop.watarelsharq.com" ? (
					<Box
						sx={{
							maxWidth: "100%",
							backgroundColor: "#48d8ff",
							position: "relative",
							width: "100%",
							height: "40px",
							padding: "20px 0",
						}}
					>
						<Box
							sx={{
								position: "absolute",
								top: 0,
								width: "100%",
								height: "100%",
								mixBlendMode: "soft-light",
								background: "url(/images/PopPattern.svg)",
							}}
						></Box>
						<Box
							component="img"
							sx={{ height: "100%" }}
							src="/images/Watar-logos.svg"
						></Box>
					</Box>
				) : (
					<Box
						sx={{
							overflowX: "clip",
							maxWidth: "100%",
							position: "relative",
						}}
					>
						<Box
							component="img"
							sx={{
								width: "100%",
								height: "40px",
								background: "#091a86",
								padding: "20px 0",
							}}
							src="/images/watar logo.svg"
						></Box>
						<Box
							component="img"
							sx={{
								height: "50vw",
								objectFit: "contain",
								position: "absolute",
								top: "-25vw",
								right: "-39vw",
							}}
							src="/images/Asset 1.png"
						></Box>

						<Box
							component="img"
							sx={{
								height: "50vw",
								objectFit: "contain",
								position: "absolute",
								top: "-27vw",
								right: "39vw",
							}}
							src="/images/Asset 2_1.png"
						></Box>
					</Box>
				)}
				<Router>
					<Routes>
						<Route path="/*" element={<MainRouter />} />
					</Routes>
				</Router>
				<ToastContainer
					position="bottom-center"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
			</Box>
		</ThemeProvider>
	);
}

export default App;
