import { Save } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Attendee, useAttendee } from "../../hooks/contexts/UseAttendeeContext";
import { makeRequest } from "../../utility/request";
import { PopupSinger } from "../PopupSinger";
import { SeatType } from "./stepper";
import { Event, useEvent } from "../../hooks/contexts/UseEventContext";

export class TicketClass {
	ticket_id: number;
	id: number;
	event_id: number;
	type: SeatType;
	first_name: string;
	seat_row: string;
	seat_column: string;
	payment_status: number;
	has_checked_in: boolean;
	area_name: string;

	constructor({
		id,
		event_id,
		type,
		first_name,
		seat_row,

		seat_column,

		payment_status,
		has_checked_in,

		area_name,
	}: {
		id: number;
		event_id: number;
		type: SeatType;
		first_name: string;

		seat_row: string;
		seat_column: string;

		payment_status: number;
		has_checked_in: boolean;

		area_name: string;
	}) {
		this.id = id;
		this.ticket_id = id;
		this.event_id = event_id;
		this.type = type;
		this.first_name = first_name;
		this.seat_row = seat_row;

		this.seat_column = seat_column;

		this.payment_status = payment_status;
		this.has_checked_in = has_checked_in;

		this.area_name = area_name;
	}
}

function Ticket({ ticket }: { ticket: TicketClass }) {
	const { attendee }: { attendee?: Attendee } = useAttendee();

	return <Ticket2 ticket={ticket} />;
}

function Ticket2({ ticket }: { ticket: TicketClass }) {
	const { event }: { event?: Event } = useEvent();

	return (
		<Paper sx={{ m: 1 }}>
			<Box
				className="ticket"
				id={`ticket_${ticket?.ticket_id}`}
				sx={{
					width: "400px",
					height: "800px",
					overflow: "hidden",
					bgcolor: "#091a86",
					position: "relative",

					display: "grid",
					gridTemplateColumns: "repeat(5,1fr)",
					gridTemplateRows: "repeat(20,1fr)",
				}}
			>
				<Box
					sx={{
						gridColumn: "1/6",
						gridRow: "1/21",
						// bgcolor: "green",
					}}
				>
					<Box
						sx={{
							objectFit: "contain",
							width: "100%",
							height: "100%",
						}}
						component={"img"}
						src={event?.ticket_template_path}
						alt=""
					></Box>
				</Box>
				<Box
					sx={{
						pt: "10px",
						gridColumn: "2/5",
						gridRow: "2/10",
						// bgcolor: "white",

						display: "flex",
						justifyContent: "center",
						alignItems: "center",

						position: "relative",
						zIndex: 2,

						boxSizing: "border-box",

						// "&:hover": editMode && {
						// 	cursor: "pointer",
						// 	outline: "1px solid rgba(255,255,255,0.5)",
						// 	filter: "brightness(1.1)",
						// },
						// display: "inline-block",
						minWidth: "unset",
					}}
				>
					<QRCode
						size={130}
						bgColor="#ffffff00"
						fgColor="#ffffff"
						value={`${window.document.location.origin}/ticket/${ticket.id}`}
					/>
				</Box>
				<Box
					sx={{
						gridColumn: "4/5",
						gridRow: "8",
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "flex-end",

						position: "relative",
						zIndex: 2,

						boxSizing: "border-box",
						// bgcolor: "black",

						fontWeight: "bold",
						fontSize: "20px",
						color: "#091a86",

						mt: "-5px",
						ml: "-35px",
					}}
				>
					{ticket.id}
				</Box>
				<Box
					sx={{
						gridColumn: "3/6",
						gridRow: "9/13",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",

						position: "relative",
						zIndex: 2,

						boxSizing: "border-box",
						// bgcolor: "green",

						fontWeight: "bold",
						fontSize: "20px",
						color: "#091a86",
						mr: "0px",
						mt: "23px",
					}}
				>
					<Box
						sx={{
							fontWeight: "bold",
							fontSize: "20px",
							whiteSpace: "break-spaces",
							// width: "70px",
						}}
					>
						{ticket.type === SeatType.CLASS_A
							? "A"
							: ticket.type === SeatType.CLASS_B
							? "B"
							: ticket.type === SeatType.CLASS_C
							? "C"
							: "D"}
					</Box>
				</Box>
				<Box
					sx={{
						gridColumn: "3/4",
						gridRow: "6/13",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",

						position: "relative",
						zIndex: 2,

						boxSizing: "border-box",
						// bgcolor: "green",
						color: "rgb(246, 138, 38)",

						fontWeight: "bold",
						fontSize: "20px",
						// color: "#091a86",
						mr: "-10px",
						mt: "20px",
					}}
				>
					<Box>{event?.getDate()}</Box> <Box>{event?.getDay()}</Box>
					{/* <Box
						sx={{
							fontWeight: "bold",
							fontSize: "20px",
							whiteSpace: "break-spaces",
							// width: "70px",
						}}
					>
						{ticket.area_name.startsWith("Area A")
							? "A & B"
							: ticket.area_name.startsWith("Area B")
							? "A & B"
							: ticket.area_name.startsWith("Area C Right")
							? "C\nRight"
							: ticket.area_name.startsWith("Area C Left")
							? "C\nLeft"
							: ticket.area_name.startsWith("Area D")
							? "D"
							: ""}
					</Box> */}
				</Box>
				<Box
					sx={{
						gridColumn: "2/4",
						gridRow: "9/13",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",

						position: "relative",
						zIndex: 2,

						boxSizing: "border-box",
						// bgcolor: "green",

						fontWeight: "bold",
						fontSize: "20px",
						color: "#091a86",
						mr: "50px",
						mt: "20px",
					}}
				>
					<Box
						sx={{
							fontWeight: "bold",
							fontSize: "20px",
							// width: "70px",
						}}
					>
						{ticket.seat_row + "-" + ticket.seat_column}
					</Box>
				</Box>
			</Box>

			<Button
				startIcon={<Save />}
				onClick={() => {
					var scaleBy = 2;
					var w = 400;
					var h = 800;

					html2canvas(
						document.getElementById(
							`ticket_${ticket?.ticket_id}`
						) as HTMLElement,
						{ scale: 1 }
					).then(function (canvas) {
						var myImage = canvas.toDataURL("image/jpeg,1.0");

						var pdf = new jsPDF("p", "px", [w, h]);
						pdf.addImage(myImage, "png", 0, 0, w, h);

						pdf.save(
							`${
								window.document.location.origin.toLocaleLowerCase() ===
								"https://pop.watarelsharq.com"
									? "وترPop"
									: "وتر الشرق"
							} - ${event?.name} - ${event?.getDate()} - ${
								ticket?.seat_row + ticket?.seat_column
							}.pdf`
						);
					});
				}}
				sx={{ width: "100%" }}
			>
				حفظ
			</Button>
		</Paper>
	);
}

// {/* <Box
// sx={{
// 	width: "400px",
// 	height: "800px",
// 	overflow: "hidden",
// 	bgcolor: "#091a86",
// 	position: "relative",
// 	boxSizing: "border-box",
// 	pt: "100px",
// }}
// >
// {/* <Box
// 	sx={{
// 		bgcolor: "green",
// 		width: "34%",
// 		height: "100%",
// 		position: "absolute",
// 		top: 0,
// 		left: 0,
// 	}}
// ></Box>
// <Box
// 	sx={{
// 		bgcolor: "white",
// 		width: "32%",
// 		height: "100%",
// 		position: "absolute",
// 		top: 0,
// 		left: "34%",
// 	}}
// ></Box>
// <Box
// 	sx={{
// 		bgcolor: "black",
// 		width: "34%",
// 		height: "100%",
// 		position: "absolute",
// 		top: 0,
// 		left: "66%",
// 	}}
// ></Box> */}
// {/* <Box
// 	component={"img"}
// 	src={"/images/Asset 2_1.png"}
// 	sx={{
// 		display: "flex",
// 		// position: "absolute",

// 		zIndex: "0",
// 		position: "absolute",
// 		transform: "scale(0.5)",
// 		top: "-500px",
// 		left: "-950px",
// 	}}
// /> */}

// {/* <Box
// 	component={"img"}
// 	src={"/images/تطريز_1.png"}
// 	sx={{
// 		display: "flex",
// 		// position: "absolute",

// 		zIndex: "0",
// 		position: "absolute",
// 		transform: "scale(0.4)",

// 		top: "97px",
// 		right: "-68px",
// 	}}
// /> */}
// <Box
// 	sx={{
// 		width: "170px",
// 		fill: "white",
// 	}}
// 	component={"svg"}
// 	xmlns="http://www.w3.org/2000/svg"
// 	viewBox="0 0 341.35 181.21"
// >
// 	<defs></defs>
// 	<title>watar logo</title>
// 	<g id="Layer_2" data-name="Layer 2">
// 		<g id="Layer_1-2" data-name="Layer 1">
// 			<path
// 				className="cls-1"
// 				d="M88.46,123q9.14-13.26,14-35.55t-7.52-35a18.5,18.5,0,0,0-4.75-2.59A4.26,4.26,0,0,0,85.06,51a40.65,40.65,0,0,0-7,11.1q-2.68,6.27-5.55,12Q68.94,80.92,72.7,85c1.55,1.68,4,2.66,7.25,3a28.67,28.67,0,0,0,9.4-.63c1.2,1.67,1.91,4.24,2.15,7.7s.36,6.39.36,8.78a66.81,66.81,0,0,1-14,9.31,65.13,65.13,0,0,1-18.45,6.53q-9.22,1.71-16.47-1.25t-8.15-14.06a50.22,50.22,0,0,1,.8-14.77,86.11,86.11,0,0,0,1.71-13.88q0-3-3.23-2.68A43.59,43.59,0,0,0,20,77.87,58.14,58.14,0,0,0,9.13,85.75Q6.44,92,4.3,98.46T0,111.54a37.77,37.77,0,0,1,25.61-15.4c.95-.12,1.58.42,1.88,1.61a12.87,12.87,0,0,1,.44,2.86,51,51,0,0,1-1.07,10q-3.22,16.83,3,25.25a22.32,22.32,0,0,0,16.66,9.31q10.38.9,22.11-4.92A52.59,52.59,0,0,0,88.46,123Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M148.48,62.22a67.8,67.8,0,0,1,8.33-12q4.56-5.19,9.4-5.73h.54q4.65,0,8.41,9.31c.48,1.08,1,1.61,1.44,1.61a1.57,1.57,0,0,0,1.25-.71q3.59-4.66,7-9.76a88.93,88.93,0,0,0,6.09-10.66,39.93,39.93,0,0,0-6.26-8.41q-3.94-3.59-7.7-3.58a9.16,9.16,0,0,0-5,1.61A53.39,53.39,0,0,0,160.3,35.54,150.68,150.68,0,0,0,150,50.85q-4.57,7.78-6.9,12.26l-.18.36.18,7.7A90.62,90.62,0,0,0,148.48,62.22Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M154.22,120.41a1.51,1.51,0,0,0,1.61,1.08q3.39,0,9.49-2.87c1.2-.56,2.28-1.13,3.27-1.7l-.59,3.32-.18.71q25.26-6.08,31.52-9.49,18.27-9.66,23.28-30.08a52.41,52.41,0,0,0,1.61-8.78A75.69,75.69,0,0,0,224.59,62L221.37,2.41,208.12,23.54q1.24,22.56,2.23,36.89t1,27.93a81.31,81.31,0,0,1-15.94,5.28q-5.52,1.2-14.8,2.78a147.12,147.12,0,0,0,2.27-14.87l-.18-.17c0,.12-.06.17-.18.17a1.32,1.32,0,0,0-.54.9q-3,12.36-16.47,14.68a32.07,32.07,0,0,0,1.07-8V87.64c0-.47-.06-.71-.18-.71a4.51,4.51,0,0,0-.53.71,18.26,18.26,0,0,0-2.15,4.21,8.08,8.08,0,0,1-2.51,3.67,42.24,42.24,0,0,1-11.28,5.91,10.22,10.22,0,0,1-3.4.72q-3.22,0-3.23-4.66V95.34c.12-1.19-.6-1.85-2.15-2a9.79,9.79,0,0,0-1.79-.18q-6.27,0-18.44,3.76-14,4.48-16.83,10a51,51,0,0,0-2.78,6.72c-1.2,3.61-2.74,7.1-4.17,10.63a10.83,10.83,0,0,1-.78,1.65,9.9,9.9,0,0,1-1.46,1.76c-6.73,6.82-15.17,12.42-23,17.87a160.67,160.67,0,0,0-20.81,17.16q-1.82,2,0,2.56,2.86.63,15-1t15.08-2.47q9.42-2.26,14.72-6.55c7.45-6,6.08-16.57,7.31-25a12.25,12.25,0,0,1,.91-3.58c.8-1.66,3.65-3.85,5.38-4.48q5.91-2.15,14-4.65a19.76,19.76,0,0,1,5.73-1.26c1.31,0,2,.48,2,1.44v2q0,5.73,5.37,5.73a16.48,16.48,0,0,0,2.33-.18q8.76-1.62,12.71-3.58A12.22,12.22,0,0,1,154.22,120.41Z"
// 			/>
// 			<polygon
// 				className="cls-1"
// 				points="248.63 76.1 243.43 0 230.18 20.95 235.38 97.06 248.63 76.1"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M300,78.51,312.47,56.6q-16.7,2.3-36.4,4.79L263.59,83.12q9.11-1.2,18.2-2.31T300,78.51Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M274.16,136.24q15.51-3.94,22.48-14.72t8.28-27.34a20.84,20.84,0,0,1-9.07,5.26,88.15,88.15,0,0,1-10.12,2.37q-11.31,2.35-21.29,3.81a42.81,42.81,0,0,0-17.62,6.44,35.08,35.08,0,0,0-11.56,12.67,59.47,59.47,0,0,0-3.56,7.42c-1,2.64-1.61,5.43-2.79,8a6.23,6.23,0,0,1-1.43,2.16,7.57,7.57,0,0,1-4.07,1.42c-1.59.25-3.12.72-4.69,1.07-4,.9-7.92,1.81-11.88,2.69-7.93,1.76-15.89,3.43-23.9,4.78A236,236,0,0,0,144.56,162c-2.46,1.05-3,2.19-1.58,3.41q3.42,2.64,20.51,8T185,179.62q13.94,2.9,23.67.52,11-2.88,18.13-16.29c2.34-4.69,5-9.27,7.45-13.91a15.13,15.13,0,0,1,2.06-3.17c1.75-1.91,4.28-2.7,6.69-3.4a76.06,76.06,0,0,1,7.72-2Q260.24,139.66,274.16,136.24Z"
// 			/>
// 			<path
// 				className="cls-1"
// 				d="M332.93,78.76a12.27,12.27,0,0,0-4.65-2.69,4.82,4.82,0,0,0-1.8-.36,4.25,4.25,0,0,0-3.22,1.61,41.35,41.35,0,0,0-7,11q-2.68,6.18-5.73,11.91a13.23,13.23,0,0,0-1.61,5.9,7.41,7.41,0,0,0,2,5.2c1.55,1.67,4,2.62,7.34,2.86a17.52,17.52,0,0,0,2.5.18,31.18,31.18,0,0,0,6.81-.72,12.73,12.73,0,0,1,1.43,3.59,8.17,8.17,0,0,1,.18,1.61,4,4,0,0,1-.54,1.43A36.4,36.4,0,0,1,315,130q-15.39,6.27-31.06,12.27a236.51,236.51,0,0,0-29.64,13.69,1.71,1.71,0,0,0-.72,1.44c0,.71.3,1.07.9,1.07q9.84,1.08,20.05,1.43h3.59a93.41,93.41,0,0,0,16.47-1.43q12-2.33,22.83-11.73a73.35,73.35,0,0,0,17.19-21.58,56.69,56.69,0,0,0,6.72-24.88V99.17A28.76,28.76,0,0,0,332.93,78.76Z"
// 			/>
// 		</g>
// 	</g>
// </Box>
// <Box sx={{ mt: "20px" }}>
// 	<QRCode
// 		size={150}
// 		bgColor="#ffffff00"
// 		fgColor="#ffffff"
// 		value={`https://watarelsharq.com/ticket/${ticket.id}`}
// 	/>
// </Box>
// <Box
// 	component={"img"}
// 	// src="/images/Asset 3.png"
// 	src={"/images/Asset 1.png"}
// 	sx={{
// 		display: "flex",
// 		// position: "absolute",

// 		zIndex: "0",
// 		position: "absolute",
// 		transform: "scale(0.5)",
// 		bottom: "-340px",
// 		left: "-800px",
// 	}}
// />

// <Box
// 	component={"img"}
// 	src="/images/العندليب.png"
// 	sx={{
// 		width: "65%",
// 		zIndex: 1,
// 		position: "absolute",
// 		bottom: "-160px",
// 		left: 0,
// 	}}
// />
// </Box>

// <Box
// sx={{
// 	width: "400px",
// 	overflow: "hidden",
// 	position: "relative",
// 	top: "-410px",
// 	display: "flex",
// 	justifyContent: "center",
// 	flexShrink: 0,

// 	flexDirection: "column",
// 	boxSizing: "border-box",
// }}
// >
// <Box sx={{ display: "flex", justifyContent: "center" }}>
// 	<Box
// 		sx={{
// 			position: "relative",
// 			display: "flex",
// 			justifyContent: "center",
// 			flexDirection: "column",
// 		}}
// 	>
// 		<Box sx={{ fontWeight: "bold", color: "white" }}>
// 			مقعد رقم
// 		</Box>
// 		<Box
// 			sx={{
// 				fontWeight: "bold",
// 				color: "white",
// 				fontSize: "30px",
// 				width: "70px",
// 			}}
// 		>
// 			{ticket.seat_row + ticket.seat_column}
// 		</Box>
// 	</Box>
// 	<Box
// 		sx={{
// 			width: "2px",
// 			height: "50px",
// 			bgcolor: "white",
// 			m: "5px 10px",
// 		}}
// 	></Box>
// 	<Box
// 		sx={{
// 			position: "relative",
// 			display: "flex",
// 			justifyContent: "center",
// 			flexDirection: "column",
// 		}}
// 	>
// 		<Box
// 			sx={{
// 				fontWeight: "bold",
// 				color: "white",
// 				fontSize: "20px",
// 				width: "70px",
// 			}}
// 		>
// 			{ticket.type === SeatType.CLASS_A
// 				? "Class A"
// 				: ticket.type === SeatType.CLASS_B
// 				? "Class B"
// 				: "Class C"}
// 		</Box>
// 	</Box>
// </Box>
// </Box>

// <Box
// sx={{
// 	width: "400px",
// 	// height: "800px",
// 	overflow: "hidden",
// 	position: "relative",
// 	top: "-420px",
// 	display: "flex",
// 	justifyContent: "center",
// 	flexShrink: 0,
// 	flexDirection: "column",
// }}
// >
// <Box
// 	sx={{
// 		position: "relative",
// 		display: "flex",
// 		justifyContent: "center",
// 		flexDirection: "column",
// 		fontSize: "25px",
// 		color: "white",
// 		fontWeight: "bold",
// 		p: "10px",
// 	}}
// >
// 	<Box>نتمنى لكم امسية ممتعة</Box>
// </Box>
// </Box>

// <Box
// sx={{
// 	width: "400px",
// 	overflow: "hidden",
// 	position: "relative",
// 	top: "-390px",
// 	display: "flex",
// 	justifyContent: "center",
// 	flexShrink: 0,
// 	flexDirection: "column",
// }}
// >
// <Box
// 	sx={{
// 		position: "relative",
// 		display: "flex",
// 		justifyContent: "center",
// 		flexDirection: "column",
// 		fontSize: "30px",
// 		color: "white",
// 		fontWeight: "bold",
// 		p: "10px 70px",
// 		alignItems: "flex-end",
// 	}}
// >
// 	<Box>{event?.getDay()}</Box>
// 	<Box>{event?.getDate()}</Box>
// </Box>
// </Box>

// <Box
// sx={{
// 	width: "400px",
// 	overflow: "hidden",
// 	position: "relative",
// 	top: "-635px",
// 	display: "flex",
// 	justifyContent: "center",
// 	flexShrink: 0,
// 	flexDirection: "column",
// }}
// >
// <Box
// 	sx={{
// 		position: "relative",
// 		display: "flex",
// 		justifyContent: "center",
// 		flexDirection: "column",
// 		fontSize: "15px",
// 		color: "white",
// 		// fontWeight: "bold",
// 		p: "10px",
// 	}}
// >
// 	<Box>Ticket No: {ticket?.ticket_id}</Box>
// </Box>
// </Box> */}

export function TicketsStep() {
	const { attendee }: { attendee?: Attendee } = useAttendee();

	const [tickets, setTickets] = useState<Array<TicketClass>>([]);
	useEffect(() => {
		makeRequest({
			endpoint: `/api/ticket/get`,
			data: {
				attendee_id: attendee?.id.toString(),
			},
			onResolve: (response: any) => {
				// // console.log(response.data);
				setTickets(
					response.data.map(
						(ticket: any) => new TicketClass({ ...ticket })
					)
				);
			},
			requestMessage: {
				pending: "Loading Tickets..",
				error: "Something went wrong! Please contact us!",
			},
		});
	}, []);

	return (
		<Box>
			<Typography
				variant="h5"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#091a86",
				}}
			>
				بتقدر تنزل تذاكرك كلهم مع بعض
			</Typography>
			<Typography
				variant="h4"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#f68a26",
				}}
			>
				او وحدة وحدة
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					mb: "80px",
					mt: "30px",
				}}
			>
				{tickets.map((ticket: TicketClass) => (
					<Ticket ticket={ticket} />
				))}
			</Box>
			<PopupSinger
				imageSrc="/images/umkalthom.png"
				text={`عظمة على عظمة
						على عظمة`}
				initialState={true}
			/>
		</Box>
	);
}
