import { useContext, useEffect, useState } from "react";
import { makeRequest } from "../../utility/request";
import EventContext from "./EventContext";

export enum EventStatus {
	Null = "null",
	HIDDEN = "HIDDEN",
	DRAFT = "DRAFT",
	SCHEDULED = "SCHEDULED",
	LAUNCHED = "LAUNCHED",
	REGISTRATION_STARTED = "REGISTRATION_STARTED",
	REGISTRATION_CLOSED = "REGISTRATION_CLOSED",
	HAPPENING = "HAPPENING",
	FINISHED = "FINISHED",
	CANCELLED = "CANCELLED",
}

export class Event {
	id: number;
	name: string;
	svg_path: string;
	ticket_template_path: string;
	event_date: Date;
	event_registration_date: Date;
	status: EventStatus;

	public getDate() {
		return (
			this.event_date.getDate() +
			"/" +
			(this.event_date.getMonth() + 1) +
			"/" +
			this.event_date.getFullYear()
		);
	}

	public getDay() {
		const days = [
			"الاحد",
			"الاثنين",
			"الثلاثاء",
			"الاربعاء",
			"الخميس",
			"الجمعة",
			"السبت",
		];
		return days[this.event_date.getDay()];
	}
	constructor({
		id,
		name,
		svg_path,
		ticket_template_path,
		event_date,
		event_registration_date,
		status,
	}: {
		id: number;
		name: string;
		svg_path: string;
		ticket_template_path: string;
		event_date: Date;
		event_registration_date: Date;
		status: EventStatus;
	}) {
		this.id = id;
		this.name = name;
		this.svg_path = svg_path;
		this.ticket_template_path = ticket_template_path;
		this.event_date = new Date(event_date);
		this.event_registration_date = new Date(event_registration_date);
		this.status = status;
	}
}
export function useEventContext({
	event_id = 0,
	name = "",
	day_of_week = 0,
	status = EventStatus.Null,
}: {
	event_id?: number;
	name?: string;
	day_of_week?: number;
	status?: EventStatus;
}) {
	const [event, setEvent] = useState<Event | null>(null);

	useEffect(() => {
		makeRequest({
			endpoint: `/api/event/get`,
			data: {
				id: event_id,
				name: name,
				day_of_week: day_of_week,
				status: status == EventStatus.Null ? null : status,
			},

			requestMessage: {
				pending: "Loading",
				error: "Something went wrong! please contact us!",
			},
			onResolve: (response: any) => {
				setEvent(new Event({ ...response.data[0] }));
			},
		});
	}, [event_id]);

	return {
		EventContext: EventContext,
		event: event,
		setEvent: setEvent,
	};
}

export function useEvent() {
	return useContext(EventContext.Context);
}
