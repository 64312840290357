import {
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	TextField,
	Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import { FormulatorMap } from "../../pages/admin/seats-map";
import Formulator from "./classes/Formulator";
import {
	useFormulator,
	useFormulatorContext,
} from "./useForm/UseFormulatorContext";
import { Event, useEvent } from "../../hooks/contexts/UseEventContext";
import { ArrowRight, ArrowRightAltRounded } from "@mui/icons-material";
import { WelcomeByNameSection } from "./WelcomeByNameSection";
import { BackButton, NextButton } from "./NextButton";
import { FormSection } from "./FormSection";
import { FormRow } from "./FormRow";
import { SeatType } from "../stepper/stepper";

export default function RegistrationFrom() {
	const [activeStepIndex, setActiveStepIndex] = useState(0);

	const {
		FormContext,
		form,
		setForm,
		handleSubmit,
		register,
		setValue,
		setError,
		getValues,
		errors,
		clearErrors,
	} = useFormulatorContext(new Formulator([]));

	const { event }: { event?: Event } = useEvent();

	const [read, setRead] = useState(false);
	useEffect(() => {
		setForm([
			[
				<Box sx={{ width: "100%" }}>
					<Typography
						variant="h4"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#091a86",
						}}
					>
						أهلا وسهلا
					</Typography>

					{window.document.location.origin.toLocaleLowerCase() ===
					"https://pop.watarelsharq.com" ? (
						<>
							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#091a86",
								}}
							>
								في حفل وتر Pop
							</Typography>
							<Box
								sx={{
									m: "auto",
									my: "30px",
									display: "flex",
									minHeight: "350px",
									width: "100%",
									maxWidth: "500px",
									background: `url(${event?.svg_path}) no-repeat center`,
								}}
							></Box>
						</>
					) : (
						<>
							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#091a86",
								}}
							>
								في حفل وتر الشرق
							</Typography>
							<Box
								sx={{
									// fill: "",
									m: "auto",
									my: "30px",
									display: "flex",
									minHeight: "150px",
									width: "100%",
									maxWidth: "500px",
									bgcolor: "#091a86",
									WebkitMask: `url(${event?.svg_path}) no-repeat center`,
									mask: `url(${event?.svg_path}) no-repeat center`,
								}}
							></Box>
						</>
					)}
					<Typography
						variant="h4"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#091a86",
						}}
					>
						{"يوم " + event?.getDay()}
					</Typography>
					<Typography
						variant="h4"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#f68a26",
						}}
					>
						{event?.getDate()}
					</Typography>

					<Typography sx={{ m: 2 }}>
						سعيدين نرجع نلتقي فيكم في حفلة جديدة من حفلات وتر الشرق
						<br></br>
						والي رح تكون بتاريخ {event?.getDate()}
						<br></br>
						لنغني سوا أغاني ل{event?.name}.
					</Typography>
					<Button
						size="large"
						sx={{ mt: 3 }}
						onClick={() => {
							handleNext(true);
						}}
						variant="contained"
					>
						<ArrowRight /> احجز تذكرتك
					</Button>
				</Box>,
			],
			[
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
					}}
				>
					{/* <Typography sx={{ m: 2, textAlign: "start" }} variant="h2">
						أهلا وسهلا
					</Typography> */}

					<Typography sx={{ m: 2, textAlign: "start" }} variant="h5">
						شوية معلومات قبل ما نبلش
					</Typography>

					<Typography sx={{ m: 2, lineHeight: "2" }} align="right">
						رح تكون الأمسية في مسرح جمعية الثقافة والتعليم
						الأرثوذكسية
						<strong>
							{" يوم " + event?.getDay() + "، "}
							{event?.getDate() + " "}
							الساعة 8:00 مساءً
						</strong>
						<br></br>
						<br></br>
						أسعار التذاكر:
						<br></br>- Class A | <strong>JOD 20</strong>
						<br></br>- Class B | <strong>JOD 15</strong>
						<br></br>- Class C | <strong>JOD 12</strong>
						<br></br>- Class D | <strong>JOD 10</strong>
						{/* <br></br>- Class C | <strong>JOD 10</strong> */}
						<br></br>
						<br></br> وتبعًا لملاحظاتكم، رح نطلب منكم نلتزم ببعض
						التعليمات، وبنتمنى منكم تقرؤوها بعناية عشان الأمسية تكون
						مريحة إلكم في المقام الأول <br></br>
						<br></br>- يتم إرسال الرابط الإلكتروني لحجز المقاعد خلال
						ثلاثة أيّام من تعبئة هذا النموذج
						<br></br>- يتم تأكيد الحجز بدفع قيمة التذاكر خلال 4
						ساعات من وقت إختيار المقاعد
						<br></br>- يتضمن التسجيل في هذا النموذج الموافقة على
						الظهور في المحتوى المرئي الخاص بـ "وتر الشرق" ويحق
						لـ"وتر الشرق" إستخدامه على المنصات الإلكترونية كما تراه
						مناسبًا، إلا أن الجلوس في المقاعد الخلفية يقلل من
						إحتمالية الظهور في التصوير.
						<br></br>- تفتح الأبواب الساعة 6:30 مساءً، يرجى الوصول
						مبكرًا لتسهيل دخولكم للمسرح
						<br></br>- كما تغلق الأبواب الساعة 8:15 مساءً، ولا يسمح
						بدخول المسرح بعد هذا الوقت أبدًا، وذلك لعدم إزعاج الحضور
						بعد بدء الحفل
						<br></br>- التأكد من وضع الهواتف النقّالة على وضع الصامت
						وعدم إجراء المكالمات أثناء العرض
						<br></br>- تجنب الأحاديث الجانبية خلال الغناء أو أثناء
						العرض الموسيقي أو بين الفقرات الموسيقية
						<br></br>- عدم التدخين في أيّ من مرافق المسرح
						<br></br>- يمنع إدخال المأكولات و المشروبات عدا الماء
						<br></br>- عدم اصطحاب الأطفال دون سن العاشرة
						<br></br>
						<br></br>بنتمنى تكون أمسية جميلة تتذكروها دايمًا!
						<br></br>
						<br></br>موقع مسرح جمعية الثقافة والتعليم الأرثوذكسية:
						<br></br>
						<a
							target="_blank"
							href="https://goo.gl/maps/EoMSjwdP51tugQG86"
						>
							https://goo.gl/maps/EoMSjwdP51tugQG86
						</a>
						<br></br>
						<br></br>
						<strong>يوجد مواقف للسيارات داخل المسرح.</strong>
						<br></br>
						<br></br>للاستفسار والمساعدة في الحجز، التواصل عن طريق
						الواتساب: عبدالله أبو هدهود
						<br></br>
						<a target="_blank" href="https://wa.me/962790089586">
							00962790089586
						</a>
					</Typography>
					{/* <Typography sx={{ m: 2, textAlign: "start" }}>
						رح تكون الأمسية في المبنى الثقافي الرياضي، في جمعية
						الثقافة والتعليم الأرثوذكسية
						<strong>
							يوم
							{" " + event?.getDay() + " "}
							{event?.getDate() + " "}
							الساعة 8:00 مساء
						</strong>
					</Typography>

					<Typography sx={{ m: 2, textAlign: "start" }}>
						أسعار التذاكر:
						<br></br>- Class A | <strong>JOD 15</strong>
						<br></br>- Class B | <strong>JOD 12</strong>
						<br></br>- Class C | <strong>JOD 10</strong>
					</Typography>

					<Typography sx={{ m: 2, textAlign: "start" }}>
						وتبعًا لملاحظاتكم، رح نطلب منكم نلتزم ببعض التعليمات،
						وبنتمنى منكم تقرؤوها بعناية عشان الأمسية تكون مريحة إلكم
						في المقام الأول
					</Typography>

					<Typography sx={{ m: 2, textAlign: "start" }}>
						- يتم إرسال الرابط الإلكتروني لحجز المقاعد خلال 48 ساعة
						من تعبئة هذا النموذج
					</Typography>

					<Typography sx={{ m: 2, textAlign: "start" }}>
						- ثم يتم تأكيد الحجز بدفع قيمة التذاكر خلال 4 ساعات من
						وقت اختيار المقاعد
					</Typography>

					<Typography sx={{ m: 2, textAlign: "start" }}>
						- يتضمن التسجيل في هذا النموذج الموافقة على الظهور في
						المحتوى المرئي الخاص ب "وتر الشرق" ويحق ل"وتر الشرق"
						استخدامه على المنصات الإلكترونية كما نراه مناسب، إلا أن
						الجلوس في المقاعد الخلفية يقلل احتمالية الظهور في
						التصوير.
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						- تفتح الأبواب الساعة 7:15 مساء، يرجى الوصول مبكرا
						لتسهيل دخولكم للمسرح
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						- كما تغلق الأبواب الساعة 8:15 مساء، ولا يسمح بدخول
						المسرح بعد هذا الوقت أبدًا، وذلك لعدم ازعاج الحضور بعد
						بدء الحفل
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						- التأكد من وضع الهواتف النقّالة على وضع الصامت وعدم
						إجراء المكالمات أثناء العرض
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						- تجنب الأحاديث الجانبية خلال الغناء أو أثناء العرض
						الموسيقي أو بين الفقرات الموسيقية
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						- عدم التدخين في أي من مرافق المسرح
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						- يمنع إدخال المأكولات و المشروبات عدا الماء
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						- عدم اصطحاب الأطفال دون سن العاشرة بنتمنى تكون أمسية
						جميلة تتذكروها دايما.
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						الموقع <br></br> OES Culture & Sports Building
						<br></br>
						<a
							target="_blank"
							href="https://goo.gl/maps/q1XdACd42TjJqq3r6"
						>
							https://goo.gl/maps/q1XdACd42TjJqq3r6
						</a>
					</Typography>
					<Typography sx={{ m: 2, textAlign: "start" }}>
						للاستفسار والمساعدة في الحجز عن طريق الواتساب:
						<br></br>
						عبدالله أبو هدهود 0096279008958
					</Typography> */}
				</Box>,

				<FormRow sx={{ justifyContent: "space-between" }}>
					<NextButton
						handleNext={handleNext}
						title="المتابعة"
						checkList={[]}
					/>

					<BackButton handleBack={handleBack} title="العودة" />
				</FormRow>,
			],
			[
				<Typography sx={{ m: 2, textAlign: "start" }} variant="h5">
					عرفنا عليك
				</Typography>,
				[
					{
						type: "text",
						label: "الاسم الاول",
						name: "first_name",
						required: true,
					},
					{
						type: "text",
						label: "اسم العائلة",
						name: "last_name",
						required: true,
					},
				],
				[
					{
						type: "date",
						label: "تاريخ الميلاد",
						name: "date_of_birth",
						required: true,
					},
				],

				<FormRow sx={{ justifyContent: "space-between" }}>
					<NextButton
						handleNext={handleNext}
						title="كيف نتواصل معك"
						checkList={["first_name", "last_name", "date_of_birth"]}
					/>

					<BackButton handleBack={handleBack} title="العودة" />
				</FormRow>,
			],
			// [
			// 	<Box sx={{ width: "100%" }}>
			// 		<WelcomeByNameSection />
			// 		<FormRow sx={{ justifyContent: "space-between" }}>
			// 			<NextButton
			// 				handleNext={handleNext}
			// 				title="كيف نتواصل معك"
			// 				checkList={[]}
			// 			/>

			// 			<BackButton handleBack={handleBack} title="العودة" />
			// 		</FormRow>
			// 		,
			// 	</Box>,
			// ],
			[
				<WelcomeByNameSection />,
				[
					{
						type: "tel",
						label: "WhatsApp number |  رقم الواتساب",
						name: "phone_number",
						required: true,
					},
				],
				[
					{
						type: "email",
						label: "Email | البريد الاكتروني",
						name: "email",
						required: true,
					},
				],

				<FormRow sx={{ justifyContent: "space-between" }}>
					<NextButton
						handleNext={handleNext}
						title="تختار فئة المقاعد"
						checkList={["phone_number", "email"]}
					/>
					<BackButton handleBack={handleBack} title="العودة" />
				</FormRow>,
			],
			[
				<Typography sx={{ m: 2, textAlign: "start" }} variant="h5">
					اختر فئة المقاعد
					<Typography sx={{ textAlign: "start" }} variant="body1">
						رح نتواصل معك لاحقا لختيار اماكن المقاعد بالتحديد.
					</Typography>
					<Typography sx={{ textAlign: "start" }} variant="body1">
						هلا بس بنحب نعرف شو فئة المقاعد الي بدك اياها ^_^{" "}
					</Typography>
				</Typography>,

				<FormulatorMap eventId={event!.id * 1} />,
				[{ type: "hidden", name: "ticket_type" }],
				<FormRow sx={{ justifyContent: "space-between", mt: "-60px" }}>
					<NextButton
						handleNext={handleNext}
						title="عدد التذاكر المطلوب"
						checkList={["ticket_type"]}
					/>

					<BackButton handleBack={handleBack} title="العودة" />
				</FormRow>,
			],
			[
				<Typography sx={{ m: 2, textAlign: "start" }} variant="h5">
					اختر عدد التذاكر المطلوب
				</Typography>,
				[
					{
						type: "select",
						name: "number_of_tickets",
						label: "Tickets amount | عدد التذاكر المطلوب",
						required: true,
						min: 1,
						max: 50,
					},
				],
				<FormRow sx={{ justifyContent: "space-between" }}>
					<NextButton
						handleNext={handleNext}
						title="المتابعة"
						checkList={["number_of_tickets"]}
					/>

					<BackButton handleBack={handleBack} title="العودة" />
				</FormRow>,
			],

			[
				<Box
					sx={{
						px: { md: 5, xs: 2 },
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<FormSummery />
					<Typography align="right" sx={{ lineHeight: "2" }}>
						<strong>التعليمات:</strong>
						<br></br>- يتم إرسال الرابط الإلكتروني لحجز المقاعد خلال
						ثلاثة أيّام من تعبئة هذا النموذج
						<br></br>- يتم تأكيد الحجز بدفع قيمة التذاكر خلال 4
						ساعات من وقت إختيار المقاعد
						<br></br>- يتضمن التسجيل في هذا النموذج الموافقة على
						الظهور في المحتوى المرئي الخاص بـ "وتر الشرق" ويحق
						لـ"وتر الشرق" إستخدامه على المنصات الإلكترونية كما تراه
						مناسبًا، إلا أن الجلوس في المقاعد الخلفية يقلل من
						إحتمالية الظهور في التصوير.
						<br></br>- تفتح الأبواب الساعة 6:30 مساءً، يرجى الوصول
						مبكرًا لتسهيل دخولكم للمسرح
						<br></br>- كما تغلق الأبواب الساعة 8:15 مساءً، ولا يسمح
						بدخول المسرح بعد هذا الوقت أبدًا، وذلك لعدم إزعاج الحضور
						بعد بدء الحفل
						<br></br>- التأكد من وضع الهواتف النقّالة على وضع الصامت
						وعدم إجراء المكالمات أثناء العرض
						<br></br>- تجنب الأحاديث الجانبية خلال الغناء أو أثناء
						العرض الموسيقي أو بين الفقرات الموسيقية
						<br></br>- عدم التدخين في أيّ من مرافق المسرح
						<br></br>- يمنع إدخال المأكولات و المشروبات عدا الماء
						<br></br>- عدم اصطحاب الأطفال دون سن العاشرة
					</Typography>
					<SubmitButton />
				</Box>,

				<FormRow sx={{ justifyContent: "end" }}>
					<BackButton handleBack={handleBack} title="العودة" />
				</FormRow>,
			],
			[
				<Box sx={{ width: "100%" }}>
					<Typography
						variant="h4"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#091a86",
						}}
					>
						تم تسجيل حجزك
					</Typography>
					<Typography
						variant="h5"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#f68a26",
						}}
					>
						رح توصلكم منا رسالة على الواتساب خلال يومين لحجز المقاعد
						تأكيد الدفع ^_^
					</Typography>

					<Typography
						sx={{
							mt: 1,
							whiteSpace: "pre-wrap",
							// fontWeight: "bold",
						}}
					>
						بنشوفكم
						<strong>
							{" يوم " + event?.getDay() + "، "}
							{event?.getDate() + " "}
							الساعة 8:00 مساءً
						</strong>
					</Typography>

					<Typography
						sx={{
							mt: 2,
							whiteSpace: "pre-wrap",
							// fontWeight: "bold",
						}}
					>
						بتقدر تسكر هاي الصفحة هلا
					</Typography>
				</Box>,
			],
		]);
	}, [event]);

	const handleNext = (override: boolean = false) => {
		if (override || activeStepIndex < form.sections.length - 1) {
			setActiveStepIndex(
				(prevActiveStepIndex) => prevActiveStepIndex + 1
			);
		}
	};

	const handleBack = (override: boolean = false) => {
		if (override || activeStepIndex > 0) {
			setActiveStepIndex(
				(prevActiveStepIndex) => prevActiveStepIndex - 1
			);
		}
	};

	return (
		<FormContext.Context.Provider
			value={{
				form: form,
				setForm: setForm,
				handleSubmit: handleSubmit,
				register: register,
				setValue: setValue,
				getValues: getValues,
				setError: setError,
				errors: errors,
				clearErrors: clearErrors,
			}}
		>
			<Container
				id="registration-container"
				maxWidth="lg"
				sx={{ p: "0 !important", mt: "-3px" }}
			>
				{form.sections.map((section, idx) =>
					idx === activeStepIndex ? (
						<FormSection
							handleNext={handleNext}
							key={idx}
							section={section}
						/>
					) : null
				)}
				{/* <Button onClick={() => handleNext()}>Next</Button>
				<Button onClick={() => handleBack()}>Back</Button> */}
				{/* <>{errors["first_name"] && errors["first_name"]["message"]}</>
				{activeStepIndex} */}
			</Container>
		</FormContext.Context.Provider>
	);
}

export function FormSummery() {
	const {
		setValue,
		getValues,
	}: { setValue?: CallableFunction; getValues?: CallableFunction } =
		useFormulator();
	// console.log(
	// 	` ${getValues!("date_of_birth").year()}-${
	// 		getValues!("date_of_birth").month() + 1
	// 	}-${getValues!("date_of_birth").date()}`
	// );
	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box sx={{ height: "30px" }}></Box>

			<Typography
				variant="h4"
				sx={{ textAlign: { xs: "start", md: "center" } }}
			>
				مبسوطين انه رح نشوفك الشهر الجاي
			</Typography>

			<br></br>

			<Typography align="right" fontWeight={"bold"} variant="h5">
				معلومات الحجز:
			</Typography>

			<Box>
				<Box sx={{ height: "30px" }}></Box>

				<Typography variant="h6" align="right">
					معلوماتك الشخصية
				</Typography>
				{/* <Box sx={{ height: "20px" }}></Box> */}

				<FormRow>
					<Typography fontWeight={"bold"}>الاسم : </Typography>
					<Box sx={{ width: "10px" }}></Box>
					<Typography>
						{getValues!("first_name")} {getValues!("last_name")}{" "}
					</Typography>
				</FormRow>
				<FormRow>
					<Typography fontWeight={"bold"}>تاريخ الميلاد :</Typography>
					<Box sx={{ width: "10px" }}></Box>

					<Typography>
						{` ${getValues!("date_of_birth").year()}-${
							getValues!("date_of_birth").month() + 1
						}-${getValues!("date_of_birth").date()}`}
						{/* {getValues!("d// console.log(getValues!("date_of_birth"))ate_of_birth").to_string()}{" "} */}
					</Typography>
				</FormRow>
			</Box>
			<Box>
				<Box sx={{ height: "30px" }}></Box>

				<Typography variant="h6" align="right">
					كيف رح نتواصل معك
				</Typography>
				{/* <Box sx={{ height: "20px" }}></Box> */}

				<FormRow>
					<Typography fontWeight={"bold"}>رقم الهاتف:</Typography>
					<Box sx={{ width: "10px" }}></Box>

					<Typography sx={{ direction: "ltr" }}>
						{getValues!("phone_number")}
					</Typography>
				</FormRow>

				<FormRow>
					<Typography fontWeight={"bold"}>
						البريد الاكتروني:
					</Typography>
					<Box sx={{ width: "10px" }}></Box>

					<Typography sx={{ direction: "ltr" }}>
						{getValues!("email")}
					</Typography>
				</FormRow>
			</Box>

			<Box>
				<Box sx={{ height: "30px" }}></Box>

				<Typography variant="h6" align="right">
					معلومات التذاكر
				</Typography>
				{/* <Box sx={{ height: "20px" }}></Box> */}

				<FormRow>
					<Typography fontWeight={"bold"}>نوع التذكرة:</Typography>
					<Box sx={{ width: "10px" }}></Box>

					<Typography sx={{ direction: "ltr" }}>
						{/* {getValues!("ticket_type")=== SeatType.CLASS_A?"Class A | فئة أ"} */}

						{getValues!("ticket_type") == SeatType.CLASS_A
							? "Class A | فئة أ"
							: getValues!("ticket_type") == SeatType.CLASS_B
							? "Class B | فئة ب"
							: getValues!("ticket_type") == SeatType.CLASS_C
							? "Class C | فئة ج"
							: "Class D | فئة د"}
					</Typography>
				</FormRow>

				<FormRow>
					<Typography fontWeight={"bold"}>عدد التذاكر:</Typography>
					<Box sx={{ width: "10px" }}></Box>

					<Typography sx={{ direction: "ltr" }}>
						{getValues!("number_of_tickets")}
					</Typography>
				</FormRow>

				<hr></hr>
				<FormRow>
					<Typography fontWeight={"bold"}>المبلغ المطلوب:</Typography>
					<Box sx={{ width: "10px" }}></Box>
					<Typography sx={{ direction: "ltr" }}>
						{Number(getValues!("number_of_tickets")) *
							Number(
								getValues!("ticket_type") == 1
									? 20
									: getValues!("ticket_type") == 2
									? 15
									: getValues!("ticket_type") == 3
									? 12
									: 10
							)}
						{" JOD"}
					</Typography>
				</FormRow>

				{/* <FormRow>
					<Typography variant="body2">
						سيتم التواصل معك خلال يومين عن طريق الواتساب لحجز
						المقاعد والدفع
					</Typography>
				</FormRow> */}
				<hr></hr>
				<Box sx={{ height: "30px" }}></Box>
			</Box>

			{/* <Typography>أهلا وسهلا</Typography>

            <Typography>{getValues!("first_name")}</Typography> */}
		</Box>
	);
}

function SubmitButton() {
	const [read, setRead] = useState(false);
	useEffect(() => {}, [read]);
	return (
		<>
			<Box sx={{ height: "30px" }}></Box>
			<FormControlLabel
				sx={{ mr: 0 }}
				required
				control={
					<Checkbox
						onChange={() => {
							setRead((prev) => !prev);
						}}
						name="read_check"
					/>
				}
				label="تاكيد قراءة التعليمات"
			/>

			<Button
				size="large"
				sx={{ m: "auto", mt: 3, width: "70%" }}
				type="submit"
				disabled={!read}
				variant="contained"
			>
				حجز التذاكر
			</Button>
		</>
	);
}
